// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
// import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
// import { connectStorageEmulator, getStorage } from 'firebase/storage';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// const firebase = initializeApp(firebaseConfig);
// const firestore = getFirestore(firebase);
// const auth = getAuth(firebase);
// const functions = getFunctions(firebase);
// const storage = getStorage(firebase);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const firestore = firebase.firestore();

const storage = firebase.storage();

const functions = firebase.functions();

// Use emulators
// if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

if (typeof window !== 'undefined' && window.Cypress) {
  // connectFunctionsEmulator(functions, 'localhost', 5001);

  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
  firestore.settings({ experimentalForceLongPolling: true });
}

export { auth, firebase, firestore, functions, storage };
