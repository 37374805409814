import * as Sentry from '@sentry/nextjs';
import { doc, setDoc } from 'firebase/firestore';
import { useFormik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { User } from 'types';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import { firestore } from '@/lib/firebase';
import useHelpAndAdvice from '@/hooks/useHelpAndAdvice';

import { useAuth } from '@/components/auth/AuthContext';

import Button from '../buttons/Button';

import Back from '~/svg/arrow-left.svg';
import Close from '~/svg/close.svg';
import Check from '~/svg/green-check-circle.svg';

const HelpAndAdvice = () => {
  const [selected, setSelected] = useState<'Help' | 'Advice'>('Help');

  const [proceed, setProceed] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { setShowHelpAndAdvice } = useHelpAndAdvice();
  const { authUser } = useAuth();

  const helpForm = useFormik({
    initialValues: {
      problem: '',
    },
    validationSchema: Yup.object({
      problem: Yup.string().trim().required('*Problem is required'),
    }),
    onSubmit: async (values, formik) => {
      try {
        const id = v4();
        const data = {
          id: id,
          user: (authUser as unknown as User)?.id ?? '',
          type: selected,
          message: values.problem,
          dateCreated: new Date().toISOString(),
          lastUpdated: new Date().toISOString(),
        };

        const _doc = doc(firestore, `feedback/${id}`);
        await setDoc(_doc, data);

        toast.success('Success');
        setProceed(false);
        setSuccess(true);
      } catch (error) {
        toast.error('Error');
        Sentry.captureException(error);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });
  return (
    <div className='relative min-h-[25vh] min-w-[40vw] rounded-lg bg-white py-5 dark:bg-gray-800 dark:text-white'>
      {!proceed && (
        <Close
          onClick={() => setShowHelpAndAdvice(false)}
          className='absolute right-4 top-4 h-5 w-5'
        />
      )}
      {proceed && (
        <Back
          onClick={() => setProceed(false)}
          className='absolute left-4 top-4 h-5 w-5'
        />
      )}
      {!proceed && !success && (
        <div className='mx-16 flex flex-col gap-4'>
          <p className='text-md text-center font-semibold'>
            Please choose an option
          </p>
          <p className='text-gray mt-6 text-center text-sm'>
            We will help you as soon as possible!
          </p>
          <div className='flex justify-center gap-4'>
            <Button
              id='help-button'
              onClick={() => setSelected('Help')}
              variant={selected == 'Help' ? 'primary' : 'gray'}
              className='rounded-lg py-1'
            >
              <span className='px-10'>Help</span>
            </Button>
            <Button
              id='advice-button'
              onClick={() => setSelected('Advice')}
              variant={selected == 'Advice' ? 'primary' : 'gray'}
              className='rounded-lg py-1'
            >
              <span className='px-10'>Advice</span>
            </Button>
          </div>
          <div className='border-b-2'></div>
          <div className='flex justify-center'>
            <Button
              id='proceed-button'
              onClick={() => setProceed(true)}
              variant='light'
              className='rounded-lg py-1'
            >
              <span className='px-10'>Continue</span>
            </Button>
          </div>
        </div>
      )}

      {proceed && (
        <div className='mx-20 flex flex-col gap-4'>
          <p className='text-center font-bold'> Please describe the problem</p>
          <textarea
            className='bg-light-gray border-gray h-max rounded-xl border-2 p-3'
            rows={4}
            id='description'
            placeholder='Please paste your message here'
            {...helpForm.getFieldProps('problem')}
          ></textarea>
          <p className='text-red -my-1 text-sm'>{helpForm.errors.problem}</p>
          <div className='border-b-2'></div>
          <div
            onClick={() => helpForm.handleSubmit()}
            className='flex justify-center'
          >
            <Button
              id='submit'
              variant='light'
              type='submit'
              className='rounded-lg py-1 shadow'
            >
              <span className='px-10'>Continue</span>
            </Button>
          </div>
        </div>
      )}

      {!proceed && success && (
        <div className='mx-28 flex h-full flex-col items-center justify-center gap-4'>
          <p className='feedback text-center font-bold'>
            {' '}
            We have sent your problem to the team, thank you!
          </p>
          <Check className='h-8 w-8' />
        </div>
      )}
    </div>
  );
};
export default HelpAndAdvice;
