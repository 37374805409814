/* eslint-disable no-console */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from '@sentry/nextjs';
import {
  GoogleAuthProvider,
  onIdTokenChanged,
  signInWithEmailLink,
  signInWithPopup,
} from 'firebase/auth';
import { User as FirebaseUser } from 'firebase/auth';
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { User, UserRoles } from 'types';

import { auth, firestore, functions } from '@/lib/firebase';

let role: 'LP' | 'GP' | 'VP' | '' = '';
let lpID = '';
let lpName = '';

const AuthContext = createContext({
  authUser: null,
  authLoading: false,
  role: '',
  signInWithGoogle: async (): Promise<any> => {},
  sendEmailLink: async (email: string): Promise<any> => {},
  confirmEmailLogin: async (email: string, url: string): Promise<any> => {},
  signOut: async () => {},
});

export const AuthProvider = ({ children }: any) => {
  const auth = useFirebaseAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const useFirebaseAuth = () => {
  const router = useRouter();

  const [authUser, setAuthUser] = useState<any>(null);
  const [authLoading, setAuthLoading] = useState(true);

  // Handle user
  const handleUser = async (rawUser: FirebaseUser | null) => {
    if (!rawUser || rawUser == null) {
      setAuthUser(null);
      setAuthLoading(false);
      return;
    }

    const userEmail = rawUser?.email as string;

    const userRoles = await getUserRoles();

    if (!userRoles) {
      setAuthUser(null);
      setAuthLoading(false);
      return;
    }

    // const emails = [...userRoles.GP, ...userRoles.VP, ...userRoles.LP];

    // if (!emails.includes(userEmail)) {
    //   setAuthUser(null);
    //   setAuthLoading(false);
    //   return;
    // }

    if (userRoles.GP.includes(userEmail)) {
      role = 'GP';
    } else if (userRoles.VP.includes(userEmail)) {
      role = 'VP';
    } else if (userRoles.LP.includes(userEmail)) {
      const data = await getLimitedPartnerIdAndName(userEmail);

      role = 'LP';
      lpID = data?.id;
      lpName = data?.name;
    } else {
      // Probably a team member
      const doc = await teamMemberExists(userEmail);

      if (doc) {
        role = 'LP';
        lpID = doc.lpId;
        lpName = doc.name;
      }
    }

    const data = await getUser(rawUser);

    if (data.success) {
      const user = data.data as User;
      user.lpID = lpID;
      user.role = role;
      if (role == 'LP') {
        user.name = lpName;
      }

      setAuthUser(user);
    } else {
      toast.error(<p data-cy='user-error'>{data.message}</p>);
    }

    setAuthLoading(false);
  };

  // Google sign in
  const signInWithGoogle = async () => {
    setAuthLoading(true);

    return await signInWithPopup(auth, new GoogleAuthProvider())
      .then(async (response) => {
        if (!response.user) {
          setAuthUser(null);
          setAuthLoading(false);
          return { success: false };
        }

        const userRoles = await getUserRoles();
        if (!userRoles) {
          setAuthUser(null);
          setAuthLoading(false);
          return { success: false };
        }

        const emails = [...userRoles.GP, ...userRoles.VP, ...userRoles.LP];

        if (!emails.includes(response.user.email as string)) {
          await signOut(false);
          return { success: false };
        } else {
          await handleUser(response.user);
          return { success: true };
        }
      })
      .catch((error) => {
        captureException(error);
        setAuthLoading(false);
        setAuthUser(null);
        toast.error(<p data-cy='google-error'>{error.code}</p>);
        return { success: false };
      });
  };

  const sendEmailLink = async (email: string) => {
    // const overrideEmail = 'kristina@nzvc.co.nz';

    const _sendEmailLink = httpsCallable(functions, 'sendEmailLink');

    return _sendEmailLink({ email })
      .then((result: any) => {
        // Read result of the Cloud Function.
        /** @type {any} */

        if (result.data.success as boolean) {
          return { success: true };
        } else {
          console.log(result.data.error);
          captureException(result.data.error);
          return {
            success: false,
            error: result.data.error.code,
            message: 'Unable to send email link. Try again later',
            // message: result.data.error.message,
          };
        }
      })
      .catch((error) => {
        captureException(error);
        return {
          success: false,
          error: error.code,
          message: 'Unable to send email link. Try again later',
          // message: error.message,
        };
      });
  };

  const confirmEmailLogin = async (email: string, url: string) => {
    console.log({ url });

    return signInWithEmailLink(auth, email, url)
      .then((result: any) => {
        // console.log(result);
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser

        return { success: true };
      })
      .catch((error) => {
        console.log(error);

        captureException(error);

        return {
          success: false,
          error: error.code,
          message: 'Link expired. Try Again',
          // message: error.message,
        };
      });
  };

  const signOut = async (redirect = true) => {
    role = '';
    await auth.signOut();
    setAuthUser(null);
    setAuthLoading(false);

    if (redirect) router.push('/login');
  };

  // listen for Firebase state change
  useEffect(() => {
    setAuthLoading(true);
    const unsubscribe = onIdTokenChanged(auth, handleUser);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    authLoading,
    signInWithGoogle,
    sendEmailLink,
    confirmEmailLogin,
    signOut,
    role,
  };
};

const getLimitedPartnerIdAndName = async (email: string) => {
  const idQuery = query(
    collection(firestore, 'limitedPartners'),
    where('emails', 'array-contains', email.toLowerCase().trim()),
    limit(1)
  );

  try {
    const data = (await getDocs(idQuery)).docs[0].data();

    return { id: data.id, name: data.name };
  } catch (error) {
    // console.log(error);
    captureException(error);
    return { id: '', name: 'User' };
  }
};

const getUserRoles = async () => {
  try {
    const userRolesQuery = query(collection(firestore, 'userRoles'), limit(1));
    const data = (await getDocs(userRolesQuery)).docs[0].data();
    return data as UserRoles;
  } catch (error) {
    // console.log(error);
    captureException(error);
    return undefined;
  }
};

const teamMemberExists = async (email: string) => {
  try {
    const teamMembersQuery = query(
      collectionGroup(firestore, 'teamMembers'),
      where('email', '==', email.toLowerCase().trim())
    );

    const querySnapshot = await getDocs(teamMembersQuery);

    return querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data() : false;
  } catch (error) {
    // console.log(error)
    captureException(error);
    return;
  }
};

const getUser = async (user: FirebaseUser) => {
  try {
    const _doc = doc(firestore, `users/${user.uid}`);
    const userDoc = await getDoc(_doc);

    if (userDoc.exists()) {
      return { success: true, data: userDoc.data() as User };
    } else {
      const newUser: User = {
        id: user.uid,
        photoUrl: user.photoURL ?? '',
        role: role,
        lpID: lpID,
        name: user.displayName ?? lpName,
        email: user.email ?? '',
      };

      await setDoc(_doc, newUser);
      return { success: true, data: newUser };
    }
  } catch (error) {
    return { success: false, error, message: 'Unable to get user' };
  }
};

// https://u22932191.ct.sendgrid.net/ls/click?upn=V-2BKWWv5Tm2Yk5N3yKKxP7r1HKXZHapJ7sL-2BCcr-2B1I41x-2BCqUfPlAbWDv8vbJD-2FUiNAgHyPA-2FXa5IVay7df0Eqo3OtHyHdxMHJwOh21JVBgdIsOsyDKD-2Fn4HUlmrFE96DaycRVLI4jGWgBKAJFU8VA6XQdC2veaO3hCtqrVhDnijeMJUakJTFKf4izuvalEo6sdC9cy-2BIhPGn277tLrthoKtepB-2FZAIEtfrF-2BbaO7jl0Ipr40e7EniwbRxqaSphQ8UkkQi5f9JyWVcaaId1Mx8DdrosIBpkK2H00s9m3aEtK9Hb7M8DT0EGm3q-2B-2FAQ1hwDUK-2FTGPd-2BE8l5358lvgAHA-3D-3DopTj_CPOV0JO-2BBWJBO1UM91qgNQefqdsP4sGhdkcnxD-2Br4Z5gubYajApgmxvUva0SeaBPsLI7B4VjjfEJEzj0MC163UhohftCUh4RO9A3NMr3Gk5h2SBmqyBGcdZShxPUPEF6s9GLQYuE8SQ-2BEMeEtMKj59K3bTaWQpb5sbWBjAs2dsc8Jzr4tmcOjZiLRa8MdFQ4t9B4RxZv0ICFbVUAu6E5cg-3D-3D
// https://nzvc-dashboard-dev-53e3a.firebaseapp.com/__/auth/action?apiKey=AIzaSyBoEGuqrZB1x16lVi42H2Gq3beSvrWfoko&mode=signIn&oobCode=KwolymD2_YpekrMPSYkpkglptf4KDaIOyJpqeXYxj98AAAGBrcIDiA&continueUrl=http://localhost:3000/login/complete-login?email%3Drtetteh.dev@gmail.com&lang=en
