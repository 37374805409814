import { useContext } from 'react';

import { HelpAndAdviceContext } from '@/pages/_app';

const useHelpAndAdvice = () => {
  const { showHelpAndAdvice, setShowHelpAndAdvice } =
    useContext(HelpAndAdviceContext);

  return { showHelpAndAdvice, setShowHelpAndAdvice };
};

export default useHelpAndAdvice;
