/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppProps } from 'next/app';
import { createContext, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import {
  firebaseReducer,
  ReactReduxFirebaseProvider,
} from 'react-redux-firebase';
import { combineReducers, createStore } from 'redux';
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'; // <- needed if using firestore

import '../styles/globals.css';
import '../styles/loaders.css';

import { firebase } from '@/lib/firebase';

import { AuthProvider } from '@/components/auth/AuthContext';
import HelpAndAdvice from '@/components/help-and-advice/HelpAndAdvice';

import { RootState } from '@/redux-types';

export const CurrencyContext = createContext<any>(null);
export const HelpAndAdviceContext = createContext<any>(null);

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Add firebase to reducers
const rootReducer = combineReducers<RootState>({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [globalCurrency, setGlobalCurrency] = useState('usd');

  const [showHelpAndAdvice, setShowHelpAndAdvice] = useState<boolean>(false);
  return (
    <AuthProvider>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <CurrencyContext.Provider
            value={{ globalCurrency, setGlobalCurrency }}
          >
            <HelpAndAdviceContext.Provider
              value={{ showHelpAndAdvice, setShowHelpAndAdvice }}
            >
              <Toaster />
              <div>
                {showHelpAndAdvice && (
                  <div
                    className={`${
                      showHelpAndAdvice
                        ? 'pointer-events-auto opacity-100'
                        : 'pointer-events-none opacity-0'
                    } translate absolute z-[999] flex h-screen w-[100%] items-center justify-center  bg-black bg-opacity-50 transition-all dark:bg-black dark:bg-opacity-50`}
                  >
                    <div
                      className={`max absolute z-50 h-max rounded-md border-2 bg-white dark:bg-[#121212] ${
                        showHelpAndAdvice
                          ? 'translate-y-0 scale-100 opacity-100'
                          : '-translate-y-5 scale-90 opacity-0'
                      }`}
                    >
                      <HelpAndAdvice />
                    </div>
                  </div>
                )}
                <Component {...pageProps} />
              </div>
            </HelpAndAdviceContext.Provider>
          </CurrencyContext.Provider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </AuthProvider>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.

// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps };
// };

export default MyApp;
